<template>
  <div>
    <a-header :backPath="`/test/${examId}/vysledky/${testId}`">
      <template v-slot:center>
        <h1 class="w-color-primary m-0">Výsledok testu</h1>
      </template>
    </a-header>

    <b-container class="a-page mb-3 mt-5">
      <b-row class="align-items-center mb-3">
        <b-col cols="9" class="d-flex align-items-center">
          <b-row class="w-100">
            <b-col cols="4" class="d-flex align-items-center">
              <h2 class="mb-0 mr-3">{{name}}</h2>
            </b-col>
            <b-col v-if="stats && stats.answers" cols="8" class="d-flex align-items-center">
              <strong class="a-answer__summary --correct">
                Správne: {{ stats.answers.correct || 0 }}
              </strong>
              <strong class="a-answer__summary --wrong">
                Nesprávne: {{ stats.answers.wrong }}
              </strong>
              <strong class="a-answer__summary --filled">
                Textové: {{ stats.answers.total - ((stats.answers.correct || 0) + stats.answers.wrong) }}
              </strong>
              <strong class="a-answer__summary --empty">
                Nevyplnené: {{ questionsCount - stats.answers.total }}
              </strong>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3" class="controls d-flex align-items-center justify-content-end">
          <span class="w-color-green">{{ score || 0 }}b.</span>
          <div class="a-separator"></div>
          <span class="w-color-green">{{ scorePercentage || 0 }} %</span>
        </b-col>
      </b-row>
      <div role="tablist">
        <div>
          <div
            class="a-question-item"
            v-for="(question, indexQ) in questions"
            :key="indexQ"
          >
            <b-card no-body>
              <b-card-header
                  header-tag="header"
                  role="tab"
              >
                <b-container>
                  <b-row class="align-items-end">
                    <b-col cols="8">
                      <b-button
                          block
                          v-b-toggle="'accordion-' + indexQ"
                          variant="bg-transparent"
                          class="text-left d-flex p-0"
                      >
                        <div class="d-flex flex-column a-question-header w-100">
                          <span class="font-weight-normal text-dark">
                              Otázka č.{{ indexQ + 1 }}
                              <strong
                                  v-if="question.type == 'choices' || question.type == 'multiselect'"
                                  class="ml-2"
                              >
                                <span 
                                  v-if="question.choices.length && question.choices[0]"
                                  :class="{
                                    'w-color-green': question.is_correct,
                                    'w-color-red': !question.is_correct
                                  }"
                                >
                                  {{ question.is_correct ? '': 'ne' }}správna odpoveď
                                </span>
                                <span v-else class="w-color-primary">
                                  nevyplnené
                                </span>
                              </strong>
                              <strong v-else class="w-color-yellow ml-2">
                                  <span :class="{ 'w-color-primary': question.answers.length && question.answers[0].body }">
                                    {{ question.answers[0].body ? '' : 'ne' }}vyplnené
                                  </span>
                              </strong>
                          </span>
                          <strong class="mt-2">{{ question.name }}</strong>
                        </div>
                      </b-button>
                    </b-col>
                    <b-col class="pr-0">
                      <b-row>
                        <b-col class="d-flex justify-content-end align-items-end">
                          <b-link
                            class="a-icon-wrapper rounded-circle --toggle ml-2"
                            href="#"
                            v-b-toggle="'accordion-' + indexQ"
                            @click.stop
                          >
                            <b-icon
                              icon="triangle-fill"
                              :rotate="question.visible ? '0' : '180'"
                            ></b-icon>
                          </b-link>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card-header>
              <b-collapse
                v-bind:id="'accordion-' + indexQ"
                :visible="question.visible"
                v-model="question.visible"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body class="px-0">
                  <div class="answers">
                    <div v-if="(question.type == 'choices' || question.type == 'multiselect')">
                      <div
                        v-for="(choice, index) in question.choices"
                        :key="index"
                        class="answer d-flex align-items-center rounded mb-1 p-3 pr-5 position-relative"
                        :class="{
                          '--correct': choice.is_correct,
                          '--selected': question.answers.length && question.answers.find(ch => ch.choice.id == choice.id)
                        }"
                      >
                        <div class="mr-5">{{ Number(index+10).toString(36).toUpperCase() }}</div>
                        <div>{{ choice.name }}</div>
                      </div>
                    </div>
                    <div class="p-3" v-else-if="question.type == 'field'">
                      <div
                        v-if="question.answers.length"
                        class="d-flex align-items-center border rounded mb-1 a-answer"
                      >
                        {{ question.answers[0].body }}
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </b-container>

    <z-loader v-if="loading"></z-loader>
  </div>
</template>

<script>
import wAxios from '@/plugins/w/axios'

const api = {
  student: (examId, testId, studentId) => wAxios.get_auth(
    `/v1/teacher/examtemplates/${examId}/exams/${testId}/students/${studentId}`
  )
}

export default {

  components: {
    'a-header': () => import('@/plugins/app/_layout/a-header.vue'),
    'z-loader': () => import('@/plugins/app@components/loader/z-loader')
  },

  data() {
    return {
      examId: this.$route.params.id,
      testId: this.$route.params.testId,
      studentId: this.$route.params.studentId,
      questions: [],
      beforeEditingCache: null,
      name: '',
      stats: [],
      score: null,
      scorePercentage: null,
      loading: false,
      questionsCount: 0
    }
  },

  async mounted() {
    if (this.examId) {
      this.loading = true
      try {
        ({ 
          data: { 
            data: {
              name: this.name,
              questions_with_answers: this.questions,
              stats: this.stats,
              score: this.score,
              score_percentage: this.scorePercentage,
              exam_questions_count: this.questionsCount 
            }
          }
        } = await api.student(this.examId, this.testId, this.studentId))
      } catch(err) {
        this.$wToast.error(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.answer
  line-height: 1rem
  --color: #F5F4FA
  --color-rgb: var(--a-color-white-rgb)
  --color-border: #F5F4FA
  background-color: rgba(var(--color-rgb), .2)

  &::before
    position: absolute
    content: ''
    border-radius: 50%
    right: 1.5rem
    top: 50%
    transform: translateY(-50%)
    background-color: var(--color)
    width: 22px
    height: 22px
    border: 6px solid var(--color-border)

  &.--selected, &.--correct
    --color-border: var(--a-color-white)

  &.--selected
    --color: var(--a-color-red-answer)
    --color-rgb: var(--a-color-red-answer-rgb)

    &.--correct
      --color: var(--a-color-green)

  &.--correct
    --color: var(--a-color-white)
    --color-rgb: var(--a-color-green-rgb)
      
</style>
